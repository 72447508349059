import { CONTENT_TYPES } from 'utils/constants';
import {
  FAQ_ITEM,
  FEATURE_SPLIT,
  IMAGE_BANNER,
  IMAGE_BANNER_WITH_TEXT_OVERLAY,
  LOGGED_IN_HOME_BANNER,
  HOME_HERO_BANNER
} from '.';
import { BUTTON_GROUP_LIST, D365Form, IFRAMEVIDOES, LIST, RichTextEditorQuillFragment, TEXT } from './common';

export const BODY_COLLECTION = `
${IMAGE_BANNER}
${LIST}
${FEATURE_SPLIT}
${IMAGE_BANNER_WITH_TEXT_OVERLAY}
${FAQ_ITEM}
${LOGGED_IN_HOME_BANNER}
${TEXT}
${HOME_HERO_BANNER}
${BUTTON_GROUP_LIST}
${D365Form}
${IFRAMEVIDOES}
${RichTextEditorQuillFragment}
fragment bodyCollectionFragment on ${CONTENT_TYPES.PAGE_BODY_COLLECTION} {
  items {
    __typename
    ...on ${CONTENT_TYPES.TEXT} {
      ...${CONTENT_TYPES.TEXT}Fragment
    }
    ...on ${CONTENT_TYPES.LIST} {
      ...${CONTENT_TYPES.LIST}Fragment
    }
    ...on ${CONTENT_TYPES.IMAGE_BANNER} {
      ...${CONTENT_TYPES.IMAGE_BANNER}Fragment
    }
    ...on ${CONTENT_TYPES.FEATURE_SPLIT} {
      ...${CONTENT_TYPES.FEATURE_SPLIT}Fragment
    }
    ...on ${CONTENT_TYPES.IMAGE_BANNER_WITH_TEXT_OVERLAY} {
      ...${CONTENT_TYPES.IMAGE_BANNER_WITH_TEXT_OVERLAY}Fragment
    }
    ...on ${CONTENT_TYPES.FAQ_ITEM} {
      ...${CONTENT_TYPES.FAQ_ITEM}Fragment
    }
    ...on ${CONTENT_TYPES.LOGGED_IN_HOME_BANNER} {
      ...${CONTENT_TYPES.LOGGED_IN_HOME_BANNER}Fragment  
    }
    ...on ${CONTENT_TYPES.HOME_HERO_BANNER} {
      ...${CONTENT_TYPES.HOME_HERO_BANNER}Fragment
    }
    ...on ${CONTENT_TYPES.BUTTON_GROUP_LIST} {
      ...${CONTENT_TYPES.BUTTON_GROUP_LIST}Fragment
    }
    ...on ${CONTENT_TYPES.D365_FORM} {
    ...${CONTENT_TYPES.D365_FORM}Fragment
    }
    ...on ${CONTENT_TYPES.IFRAME_VIDEO} {
    ...${CONTENT_TYPES.IFRAME_VIDEO}Fragment
    }
    ...on ${CONTENT_TYPES.RICH_TEXT}{
    ...${CONTENT_TYPES.RICH_TEXT}Fragment
    }
  }  
}
`;
