import { IMAGE_FRAGMENT } from 'graphql/fragments';
import { BODY_COLLECTION } from 'graphql/fragments/body';
import { CONTENT_TYPES } from 'utils/constants';

export const pageQuery = `
${IMAGE_FRAGMENT}
${BODY_COLLECTION}
query PageQuery($slug: [String], $preview: Boolean) {
  pageCollection(limit: 1, where: { slug_in: $slug }, preview: $preview) {
    items {
      ...on ${CONTENT_TYPES.PAGE} {
        title
        slug
        parentSlug
        headlineH1
        headlineH1Part2
        headerBackgroundColor
        headerIconPattern {
          ...Image
        }
        headerImage {
          ...Image
        }
        pageTitleMeta: pageTilteMeta
        pageDescriptionMeta
        bodyCollection(limit: 8) {
          ...bodyCollectionFragment
        }
      }
    }
  }
}
`;
