import { ReactElement, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import Error from 'next/error';

import fetchContentfulData from 'api/contentful-fetch';
import { UnknownObjectAny } from 'types/global.types';
import { PageCMSData } from 'types/cms/fragments';
import { pageQuery } from 'graphql/queries/page';
import { Banner, ComponentGenerator } from 'components/CMS';
import { graphQLErrorHandler } from 'utils/graphQLHandlers';
import CenterContent from 'components/containers/CenterContent';
import PageHero, { PageHeroProps } from 'components/CMS/Heros/PageHero';

// The 404 page has to be statically built at build time, so we have to fetch the data here in the component instead of using getServerSideProps
const NotFound = (): ReactElement => {
  const [content, setContent] = useState<PageCMSData>();
  useEffect(() => {
    const fetch404PageContent = async () => {
      const contentfulData = (await fetchContentfulData({
        query: pageQuery,
        variables: {
          slug: '404'
        }
      })) as UnknownObjectAny;

      graphQLErrorHandler('pageQuery', contentfulData?.errors, pageQuery);

      setContent(contentfulData?.data?.pageCollection?.items?.[0]);
    };
    fetch404PageContent();
  }, []);

  // Just in case Contentful fails to return data - we want to show something
  if (!content) {
    return <Error statusCode={404} />;
  }

  return (
    <>
      {content?.pageAlertBanner && <Banner isPageAlertBanner {...content?.pageAlertBanner} />}
      <main>
        {content && <PageHero {...(content as PageHeroProps)} />}
        <Box>
          <CenterContent>
            {content?.bodyCollection?.items?.map(({ __typename, ...rest }, index) => (
              <ComponentGenerator key={`component-${index}`} __typename={__typename} {...rest} />
            ))}
          </CenterContent>
        </Box>
      </main>
    </>
  );
};

export default NotFound;
